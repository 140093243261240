export default {
  name: 'ClubsIndex',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
